import React from 'react';
import LinkCustom from '../components/TransitionLinks/LinkCustom';


function PageNotFound() {
  return (

    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center">
            {/* Top image */}
            <div className="relative inline-flex flex-col mb-6" data-sal="slide-up" data-sal-duration="800" data-sal-easing="easeIn">
              <img  src={require('../images/404.png')} width="196" height="196" alt="404" />
            </div>
            {/* 404 content */}
            <div className="404content" data-sal="slide-down" data-sal-duration="800" data-sal-easing="easeIn">
              <h1 className="h1 mb-4">That page does not exist.</h1>
              <p className="text-lg text-gray-400" data-sal="zoom-in" data-sal-duration="800" data-sal-easing="easeIn" data-sal-delay="400">Head to our <LinkCustom to="/" className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">homepage</LinkCustom> or try double-checking the URL.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default PageNotFound;
